<template>
    <div>
        <div class="md-booking-toolbar" style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-start; align-items:  center; align-content: center; height: 50px; background-color: #fff" >
            <div class="" style="width: 40px; text-align: center" >
                <a @click=" $router.go(-1)" style="padding: 10px 10px 10px 2px" >
                    <md-icon>arrow_back</md-icon>
                </a>
            </div>
            <h3>
                {{lang.editar_blokeo.toUpperCase()}}
            </h3>
        </div>
        <md-dialog-alert :md-active.sync="alert.show" :md-content="alert.text"  :md-confirm-text="lang.cerrar" />
        <md-dialog-confirm class="accept" :md-active.sync="alert.show2" :md-content="alert.text" md-cancel-text=""  md-confirm-text="OK"  @md-confirm="onConfirm" />
        <div class="md-content createlock">
            <md-chip style="margin-bottom: 1em">
                <md-icon>bed</md-icon> <strong>{{bloq.categoria}}</strong> | {{bloq.habitacion}} | {{bloq.tipo}}
            </md-chip>
            <div class="md-layout md-gutter">
                <div class="md-layout-item">
                    <div>
                        <md-datepicker v-model="selected.ingreso" :class="{'md-invalid': form_error.ingreso}">
                            <label>{{lang.desde}}</label>
                            <span class="md-error">{{lang.requerido}}</span>
                        </md-datepicker>
                    </div>
                </div>

                <div class="md-layout-item">
                    <md-datepicker v-model="selected.salida" :class="{'md-invalid': form_error.salida}">
                        <label>{{lang.hasta}}</label>
                        <span class="md-error">{{lang.requerido}}</span>
                    </md-datepicker>
                </div>
            </div>
            <div class="md-layout md-gutter" v-if="!selected.eliminacion_auto.date">
                <div class="md-layout-item md-size-100 sub-head">
                    <md-switch v-model="selected.eliminacion_auto.show" class="md-primary">{{lang.delete_auto}} </md-switch>
                </div>

                <div class="md-layout-item md-size-40" v-if="selected.eliminacion_auto.show">
                    <md-field>
                        <md-icon>timer_off</md-icon>
                        <md-input type="number" name="tiempo" id="tiempo" v-model="selected.eliminacion_auto.tiempo" :disabled="sending" />
                    </md-field>
                </div>
                <div class="md-layout-item  md-size-60" v-if="selected.eliminacion_auto.show">
                    <md-field >
                        <md-select v-model="selected.eliminacion_auto.formato"  md-dense>
                            <md-option value="">{{lang.seleccione}} </md-option>
                            <md-option value="minutes">{{lang.minutos}} </md-option>
                            <md-option value="hours">{{lang.horas}} </md-option>
                            <md-option value="days">{{lang.dias}} </md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-100 alert" v-if="details.fecha_eliminacion != ''">
                    {{lang.delete_msg}} {{details.fecha_eliminacion}}
                </div>
            </div>
            <div class="md-layout-item md-size-100 alert" v-else style="display: flex; justify-content: space-between; align-items: center">
               <span> {{lang.delete_msg}} {{selected.eliminacion_auto.date}} </span>
                <a @click="selected.eliminacion_auto.date = false">   <md-icon>close</md-icon></a>
            </div>

            <div class="md-layout-item md-size-100">
                <md-field>
                    <label>{{lang.notas}}</label>
                    <md-textarea v-model="selected.notas"></md-textarea>
                </md-field>
            </div>

            <div v-if="details.noches"  class="md-layout-item" style="border: 1px dashed  dimgrey; font-size: 16px; display: flex; align-items: center; justify-content: center; height: 40px; margin-bottom: 1em">
                <div class = "text-center" style=" display: flex; align-items: center; ">
                    <md-icon class = "md-large">night_shelter</md-icon> {{details.noches}}<span style="margin-left: 7px"> {{lang.noches}}</span>
                </div>
            </div>
            <div class="md-layout-item" style="display: flex; justify-content: space-between">
                <md-button class="md-raised md-accent" @click="setDelete()" ><md-icon>delete_outline</md-icon> {{lang.eliminar}} </md-button>
                <md-button class="md-raised md-primary" @click="setDone('first')">{{lang.guardar}} <md-icon>check</md-icon></md-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {empty, request, avatar, Utility} from '../../../helpers';
    import moment from 'moment';
    export default {
        name: 'CalendarioEditBloqueo',
        data: () => ({
            alert: {
                show : false,
                text : 'test',
                show2: false
            },
            bloq : {},
            sending: false,
            form_error :{
                tipo : false,
                categoria:false,
                cuarto : false,
                ingreso: false,
                salida : false,
                tiempo : false,
                formato : false,
            },
            eliminar: false,
            selected : {
                id : null,
                ingreso : null,
                salida  : null,
                tipo: '',
                categoria: '',
                cuarto  : '',
                noches  : 0,
                eliminacion_auto:  {
                    show : false,
                    tiempo : '',
                    formato : '',
                    date : false
                },
                notas : '',
            }
        }),
        computed: {

            lang() {return this.$store.state.lang},
            details(){

                let ingreso = null;
                let salida  = null;
                let noches = null;
                let tiempo = 0;
                let formato = '';
                let notas = this.selected.notas;
                let fecha_eliminacion = '';

                if(this.selected.ingreso && this.selected.salida ) {
                    ingreso = moment(this.selected.ingreso);
                    salida = moment(this.selected.salida);
                    noches =  salida.diff(ingreso, 'days');
                }

                if(this.selected.eliminacion_auto.show){
                    tiempo = this.selected.eliminacion_auto.tiempo;
                    formato = this.selected.eliminacion_auto.formato;
                    if(!empty(tiempo) && !empty(formato)) {
                        fecha_eliminacion = moment().add(tiempo, formato).format('DD/MM/YY HH:mm')
                    }
                }else{
                    this.selected.eliminacion_auto.formato = '';
                    this.selected.eliminacion_auto.tiempo = '';
                }

                return {
                    ingreso,
                    salida,
                    noches,
                    notas,
                    formato,
                    tiempo,
                    fecha_eliminacion
                }
            }
        },
        methods: {
            onConfirm(){
                this.$router.push({name:'calendario'})
            },
            async setDone (step) {
                let error  = 0;
                switch (step) {
                    case 'first' :
                        this.reset();

                       if(empty(this.selected.ingreso)){
                            this.form_error.ingreso = true;
                            error++;
                        }

                        if(empty(this.selected.salida)){
                            this.form_error.salida  = true;
                            error++;
                        }

                        if(this.details.noches < 1){
                            this.form_error.ingreso = true;
                            this.form_error.salida  = true;
                            error++;

                            this.alert.show = true;
                            this.alert.text = this.lang.seleccione_fecha_rango;
                            this.alert.btn = this.lang.cerrar;

                        }

                        if(error  == 0 || this.eliminar ){
                            let url = base_url+'/bloquearHabitacion/editar?view=json&jwt='+this.$store.state.jwt;
                            if(this.eliminar){
                                this.eliminar = false;
                                url = base_url+'/bloquearHabitacion/eliminar?view=json&jwt='+this.$store.state.jwt
                            }
                            let bloqueo = {
                                "idReserva": this.bloq.id_reserva,
                                "idHabitacion": this.bloq.id_cuarto,
                                "multiple": "0",
                                "desde":  this.details.ingreso.format('DD/MM/YYYY'),
                                "hasta": this.details.salida.format('DD/MM/YYYY'),
                                "date_bloquear":  (this.selected.eliminacion_auto.date)? this.selected.eliminacion_auto.date : this.details.fecha_eliminacion,
                                "nota": this.details.notas
                            };

                            this.$store.state.loading = true;
                            let response  = await request(url, Utility.convertModelToFormData(bloqueo));
                            this.$store.state.loading = false;
                            if(response.ok) {
                                response = response.r;
                                if (typeof response.data == 'object') {
                                    if (response.data.status != 'ok') {
                                        this.alert.text = response.data.message;
                                        this.alert.show = true;
                                        return
                                    }

                                    this.alert.text = response.data.message;
                                    this.alert.show2 = true;

                                }
                            }
                        }

                        break;
                }
            },
            setDelete(){
                this.eliminar = true;
                this.setDone('first');
            },
            reset() {
                this.form_error = {
                    tipo            : false,
                    categoria       : false,
                    cuarto          : false,
                    ingreso         : false,
                    salida          : false,
                    formato			: false,
                    tiempo			: false
                }
            }
        },
        async beforeMount(){
            if(this.$route.params.id) {
                let url = base_url + '/generarCalendario/detalles?view=json&jwt=' + this.$store.state.jwt +'&item='+this.$route.params.id;
                let r = await request(url);
                if(r.ok){
                    if(r.r.data){
                        let bloq = r.r.data;
                        this.bloq = bloq;
                        this.selected.ingreso = bloq.fecha_ingreso;
                        this.selected.salida = bloq.fecha_salida;
                        this.selected.notas = bloq.nota;
                        if((!empty(bloq.delete_date))){
                            this.selected.eliminacion_auto.date = moment(bloq.delete_date).format('DD/MM/YY HH:mm');
                        }
                    }
                }
            }
        },
        async mounted() {
            this.$store.state.loading = false;
        }
    }
</script>

<style lang="scss" >
    .md-dialog.accept{

    }
    .md-content.createlock {
        position: relative;
        height: calc(100vh - 115px);
        overflow: auto;
        padding: 30px;
        .sub-head{
            font-size: 14px;
            color: rgba(0,0,0,.6);
        }
        .md-select-value{
            padding-left: 13px;
        }

        .details {
            font-size: 14px;
            border: 1px dashed  dimgrey;
            .md-layout-item {
                padding: 7px;
                text-align: center;

                i{
                    font-size: 20px;
                }
                .label {
                    font-size: 11px;
                    color: rgba(0, 0, 0, 0.54)
                }
            }
        }
        .tarifas{
            margin-top: 30px;
            .md-field {
                width: 100%;
                min-height: 48px;
                margin: 0px 0 11px;
                padding-top: 16px;
                display: flex;
                position: relative;
                font-family: inherit;
            }
        }

        .alert {
            text-align: center;
            margin-bottom: 10px;
            background-color: #ff5252;
            color: white;
            font-size: 14px;
            padding: 7px;
        }
    }
</style>
